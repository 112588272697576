import React, { useEffect } from 'react';

import axios from 'axios';

import App from './App';
import Login from './components/Login';

import { useSelector, useDispatch } from 'react-redux';

import { setIsAuthenticated, setToken } from "./store/auth/reducers";

function AuthWrapper() {
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated); // determine if authorized, from context or however you're doing it

    const dispatch = useDispatch()

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
          axios.get('https://sharpnetdevs.com/api/check_token', {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
          .then(() => {
            dispatch(setIsAuthenticated(true));
            dispatch(setToken(token));
          })
          .catch(() => {
            // Handle invalid token case
            localStorage.removeItem('token');
          });
        }
      }, []);
      

    return (
        <>
            {isAuthenticated ? <App />: <Login />}
        </>
    );
}

export default AuthWrapper;