import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import { useSelector, useDispatch } from 'react-redux'
import { setSelectedApplicationsCard } from '../store/applications/reducer';
import { setSelectedServersCard } from '../store/servers/reducer'

function SideBarData(props) {
    const [ dataToShow, setDataToShow ] = useState(props.dataType);

    useEffect(() => {
        setDataToShow(props.dataType)
    },[props])

    const theme = useTheme()

    const dispatch = useDispatch()
    const filteredApplicationsData = useSelector(state => state.applications.filteredApplicationsData)
    const filteredServersData = useSelector(state => state.servers.filteredServersData)


    const handleApplicationClick = (app) => {
        if(dataToShow === 'apps')
        dispatch(setSelectedApplicationsCard(app));    
        if(dataToShow === 'servers')
        dispatch(setSelectedServersCard(app));   
    }

    const renderComponent = () => {
        console.log(dataToShow)
        switch(dataToShow) {
          case 'apps':
            return (filteredApplicationsData.map((app, index) => (
                <Card
                    key={app.id}
                    onClick={() => handleApplicationClick(app)}
                    sx={{
                    marginTop: 1,
                    padding: 1,
                    paddingLeft: 2,
                    background: theme.palette.primary.main,
                    height: '100px',
                    minHeight: '100px',
                    cursor: 'pointer',
                    }}
                >
                    <Typography variant="h6" component="div">
                    {app.name}
                    </Typography>
                    <Typography variant="h6" sx={{ mb: 1.5 }}>
                    {app.ip}
                    </Typography>
                </Card>
                )))
          case 'servers':
            return (filteredServersData.map((app, index) => (
                <Card
                    key={app.id}
                    onClick={() => handleApplicationClick(app)}
                    sx={{
                    marginTop: 1,
                    padding: 1,
                    paddingLeft: 2,
                    background: theme.palette.primary.main,
                    height: '100px',
                    minHeight: '100px',
                    cursor: 'pointer',
                    }}
                >
                    <Typography variant="h6" component="div">
                    {app.name}
                    </Typography>
                    <Typography variant="h6" sx={{ mb: 1.5 }}>
                    {app.ip}
                    </Typography>
                </Card>
                )))
          default:
            return null;
        }
      }

    return (
        <>
            {renderComponent()}
        </>
    );
}

export default SideBarData;
