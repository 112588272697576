import { createSlice } from "@reduxjs/toolkit";

const authReducer = createSlice({
    name: 'auth',
    initialState: {
        isAuthenticated: false,
        isLoading: false,
        error: null,
        token: null
    },
    reducers: {
        setIsAuthenticated(state, action) {
            state.isAuthenticated = action.payload
        },
        setToken(state, action) {
            state.token = action.payload
        }
    }
})

export const { setIsAuthenticated, setToken } = authReducer.actions;
export default authReducer.reducer