import { Grid, Typography, Card, FormControlLabel, Checkbox, TextField, Box, Link, Alert } from "@mui/material"
import LaunchIcon from '@mui/icons-material/Launch';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTheme } from '@mui/material/styles';

import { useDispatch, useSelector } from 'react-redux'
import { updateApplicationNotes, updateApplicationDevTemp } from '../store/applications/reducer'

import axios from "axios";

import { useState, useCallback, useEffect } from "react";
import { debounce } from "lodash";


function DetailedApplicationsData() {
    const selectedApplicationsCard = useSelector(state => state.applications.selectedApplicationsCard)
    const token = useSelector(state => state.auth.token); // Get token from Redux state

    const [localNotes, setLocalNotes] = useState();

    useEffect(() => {
        if(selectedApplicationsCard) {
            setLocalNotes(selectedApplicationsCard.notes)
        }
    },[selectedApplicationsCard])
    
    const dispatch = useDispatch()
    
    const [showAlert, setShowAlert] = useState(false);

    const theme = useTheme()

    const copyToClipboard = (copy) => {
        navigator.clipboard.writeText(copy)
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 2500);
      };
    
    const handleNotesChange = useCallback(
      debounce(async (id, newNotes) => {
        try {
          const response = await axios.put(`https://sharpnetdevs.com/api/data/applications/notes/${id}`, {
            notes: newNotes
          }, {
            headers: {
              Authorization: `Bearer ${token}` // Pass token in the Authorization header
            }
          });
          dispatch(updateApplicationNotes({id, newNotes}));
          console.log(response.data);
          // Handle the response as needed
        } catch (error) {
          console.log(error);
          // Handle the error as needed
        }
      }, 500), []
    );
      
      
    const handleChange = (id, newNotes) => {
        setLocalNotes(newNotes);
        handleNotesChange(id, newNotes);
    };

    const handleDevTempChange = (event) => {
      const newDevTempValue = event.target.checked;
      const id = selectedApplicationsCard.id;
  
      // Send PUT request to update devTemp field in backend
      axios.put(`https://sharpnetdevs.com/api/data/applications/devTemp/${id}/${String(newDevTempValue)}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          dispatch(updateApplicationDevTemp({id, newDevTempValue}));
          // Handle success response as needed
          // console.log(response.data);
        })
        .catch(error => {
          // Handle error as needed
          console.error(error);
        });
  };
  
      
    

    const renderDetailedApps = () => {
        if(selectedApplicationsCard) {
            return(
                <>
                    {showAlert && (
                        <Alert
                        sx={{
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            width: '10%',
                            zIndex: (theme) => theme.zIndex.snackbar + 1,
                            margin: 1,
                        }}
                        severity="success"
                        onClose={() => setShowAlert(false)}
                        >
                        Copied! 
                        </Alert>
                    )}
                    <Grid item xs={12}>
                        {selectedApplicationsCard.name ? 
            
                        <Typography variant='h3' color={theme.palette.primary.main} fontWeight="bold">
                            {selectedApplicationsCard.name}
                        </Typography> 
                        :
                        <Typography variant='h3' color={theme.palette.primary.main} fontWeight="bold">
                            No "name" found
                        </Typography>
                        }
                        {selectedApplicationsCard.cname ? 
                        <Link sx = {{textDecoration: "none"}} href={'https://' + selectedApplicationsCard.cname} target="_blank">
                            {
                                <Typography variant='h5' color={theme.palette.primary.main}>
                                {selectedApplicationsCard.cname}
                                <LaunchIcon 
                                    sx = {{
                                        paddingTop: 1
                                    }}
                                />
                                </Typography>
                            }
                        </Link> : 
                        <Typography variant='h5' color={theme.palette.primary.main}>No "cname" found</Typography>}
                    </Grid >
                    <Grid item xs={2}>
                        <Card onClick = {() => copyToClipboard(selectedApplicationsCard.id)} sx = {{padding: 2, background: theme.palette.primary.main}}>
                            <Typography variant='h6'>
                            ID
                            </Typography>
                            <Typography variant='p'>
                            {selectedApplicationsCard.id}
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={2}>
                        <Card sx = {{padding: 2, background: theme.palette.primary.main}}>
                            <Typography variant='h6'>
                            SSL
                            </Typography>
                            <Typography variant='p'>
                            {selectedApplicationsCard.ssl}
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={2}>
                        <Card sx = {{padding: 2, background: theme.palette.primary.main}}>
                            <Typography variant='h6'>
                            IP
                            </Typography>
                            <Typography variant='p'>
                            {selectedApplicationsCard.ip}
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={2}>
                        <Card sx = {{padding: 2, background: theme.palette.primary.main}}>
                            <Typography variant='h6'>
                            Cost
                            </Typography>
                            <Typography variant='p'>
                            {selectedApplicationsCard.cost ? selectedApplicationsCard.cost: "Not available"}
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={2}>
                        <Card sx = {{padding: 2, background: theme.palette.primary.main}}>
                            <Typography variant='h6'>
                            Backups
                            </Typography>
                            <Typography variant='p'>
                            {selectedApplicationsCard.backups ? "Active": "Not available"}
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={2}>
                        <Card sx = {{padding: 2, background: theme.palette.primary.main}}>
                            <Typography variant='h6'>
                            SMTP
                            </Typography>
                            <Typography variant='p'>
                            {selectedApplicationsCard.smtp}
                            </Typography>
                        </Card>
                    </Grid>
                      <Grid item xs={4}>
                      <TextField
                        id="outlined-multiline-static"
                        label="Notes"
                        multiline
                        rows={4}
                        fullWidth={true}
                        value={localNotes}
                        onChange = {(e) => handleChange(selectedApplicationsCard.id, e.target.value)}
                        sx={{
                            '&:hover:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
                              borderColor: theme.palette.secondary.main,
                            }
                        }}
                        InputProps={{
                            style: {
                              color: theme.palette.primary.main,
                            },
                          }}
                        InputLabelProps={{ shrink: true }}
                       />
                      </Grid>
                      <Grid item xs={4}>
                        <Card sx={{padding: 2, display: "flex", gap: 3, background: theme.palette.primary.main}}>
                          <Box sx={{display: "flex", flexDirection:"column"}}>
                            <Typography>
                              <span style={{marginRight: "8px"}}>Status:</span>
                              {selectedApplicationsCard.active ? <span style={{color: "green"}}>Online</span> : <span style={{color: "red"}}>Offline</span>}
                            </Typography>
                            <Typography>
                              <span style={{marginRight: "8px"}}>Ping Test:</span>
                              {selectedApplicationsCard.ping_test ? <span style={{color: "green"}}>TESTING</span> : <span style={{color: "red"}}>False</span>}
                            </Typography>
                            <Typography>
                            <FormControlLabel
                                control={<Checkbox checked={selectedApplicationsCard.devTemp} sx={{color: "white", '&.Mui-checked': {color: "white",},}} />}
                                label="Dev Site"
                                onChange={handleDevTempChange}
                                />
                            </Typography>
                          </Box>
                        </Card>
                        </Grid>
                        <Grid item xs={4}>
                          <Card sx={{padding: 4, background: theme.palette.primary.main, display: "flex", gap: "10px"}}>
                          <TextField
                            label="Username"
                            value={selectedApplicationsCard.username}
                            InputProps={{
                              endAdornment: <ContentCopyIcon onClick={() => copyToClipboard(selectedApplicationsCard.username)} sx={{ cursor: 'pointer' }} />,
                              sx: {
                                color: theme.palette.text.primary,
                                '&::placeholder': {
                                  color: theme.palette.text.primary,
                                },
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: theme.palette.text.primary,
                              },
                            }}
                            sx={{
                              '& .Mui-disabled': {
                                color: theme.palette.text.primary,
                              },
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: theme.palette.text.primary,
                                },
                                '&:hover fieldset': {
                                  borderColor: theme.palette.text.primary,
                                },
                              },
                              '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.text.primary,
                              },
                            }}
                          />
                          <TextField
                            label="Password"
                            value={selectedApplicationsCard.password}
                            type="password"
                            InputProps={{
                              endAdornment: <ContentCopyIcon onClick={() => copyToClipboard(selectedApplicationsCard.password)} sx={{ cursor: 'pointer' }} />,
                              sx: {
                                color: theme.palette.text.primary,
                                '&::placeholder': {
                                  color: theme.palette.text.primary,
                                },
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: theme.palette.text.primary,
                              },
                            }}
                            sx={{
                              '& .Mui-disabled': {
                                color: theme.palette.text.primary,
                              },
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: theme.palette.text.primary,
                                },
                                '&:hover fieldset': {
                                  borderColor: theme.palette.text.primary,
                                },
                                '&.Mui-focused': {
                                  borderColor: theme.palette.text.primary,
                                },
                              },
                            }}
                          />
                          </Card>
                        </Grid>
                </>
            )
        }
        else {
            return(
                <Typography variant = 'h4' sx={{color: theme.palette.secondary.main, padding: '45px 40px'}}>Select a project to display more information</Typography>
            )
        }
    }
    
    return(
        renderDetailedApps()
    )
}


export default DetailedApplicationsData;
