import Box from '@mui/material/Box';
import { AppBar, CssBaseline, Drawer, Toolbar, Typography, TextField, ButtonGroup, Button, Grid, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import AppsIcon from '@mui/icons-material/Apps';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import StorageIcon from '@mui/icons-material/Storage';
import BuildIcon from '@mui/icons-material/Build';

import { useEffect, useState } from 'react';

import DetailedApplicationsData from './components/DetailedApplications'
import DetailedServers from './components/DetailedServers';
import AppCloner from './components/AppCloner';

import { useSelector, useDispatch } from 'react-redux'
import { fetchApplicationsData, filterApplications } from './store/applications/reducer';

import { debounce } from 'lodash';
import SideBarData from './components/SideBarData';
import { fetchServersData, filterServers } from './store/servers/reducer';

function App() {
  const [ selectedDataType, setSelectedDataType ] = useState('apps')
  const [ showCloner, setShowCloner ] = useState(false)

  const theme = useTheme()

  const dispatch = useDispatch()

  const isLoading = useSelector(state => state.applications.isLoading)
  const error = useSelector(state => state.applications.error)


  //make this a seperate file, please
  const greetingText = [
      "Powered by Taco Bell", 
      "Shouldn't you be running reports right now?",
      "If you're reading this, you should probably get back to work",
      "I am Trent",
      "SharpNet Rulez!",
      "Sneak Link? 👀",
      "Darrah > Steele",
      "Fun fact, clicking this text does nothing!",
      "Please reach out to james@sharpnetsolutions.com for a raise",
      "I know what you did...",
      "Whoa whoa whoa don't click so hard",
      "You just wasted so much time reading this",
      "If there's a problem with this tool, it's most likely a user error",
      "I wanna go lay down on College",
      "Attractive Web Developers near me",
      "Time for a Cheeba Hut injection",
      "Robust Philly Cheese Steaks",
      "Jack is back"
    ]

  useEffect(() => {
    dispatch(fetchApplicationsData());
    dispatch(fetchServersData())
  }, [dispatch])

  useEffect(() => {
    setSelectedGreetingText(selectRandomItemFromArray(greetingText))
  },[])

  const [ selectedGreetingText, setSelectedGreetingText ] = useState()
  
  function selectRandomItemFromArray(array) {
    return array[Math.floor(Math.random() * array.length)];
  }

  const handleButtonClick = (dataType) => setSelectedDataType(dataType);

  const handleShowCloner = () => {
    console.log("CLICKED");
    setShowCloner(true)
  }

  const handleSearchChange = debounce((search) => {
    if(selectedDataType === 'apps') {
      dispatch(filterApplications(search))
    }

    if(selectedDataType === 'servers') {
      dispatch(filterServers(search))
    }
  }, 200);


  const renderComponent = () => {
    switch(selectedDataType) {
      case 'apps':
        return <DetailedApplicationsData />;
      case 'servers':
        return <DetailedServers />;
      default:
        return null;
    }
  }

  return (
    <Box 
      sx ={{display: "flex"}}
    >
      {showCloner && <AppCloner setShowCloner={setShowCloner}/>}
      <CssBaseline />
      <AppBar position='fixed' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Typography variant='h4'>Bombadil | {selectedGreetingText}</Typography>
        </Toolbar>
      </AppBar>
      <Box>
      <Drawer 
        variant='permanent'
        sx={{
          width: 600,
          ['& .MuiDrawer-paper']: {width: 600}
        }}>
        <Toolbar />
          <Box sx={{
            marginTop: 1,
            padding: 2,
            display: "flex",
            flexDirection: 'column',
            position: 'sticky',
            top: 10,
            zIndex: 0,
            '&:hover:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.secondary.main,
            },
          }}>
          <TextField 
            id="outlined-basic"
            label="Search" 
            variant="outlined" 
            sx={{marginBottom: 1}}
            onChange = {(e) => handleSearchChange(e.target.value)}
            InputProps={{
              style: {
                color: theme.palette.primary.main,
              },
            }}
          />
          <ButtonGroup 
            size="large"
            fullWidth = {true}
            color= "secondary"
            sx ={{
              display: "flex",
              margin: "auto",
            }}
          >
          <Button 
          startIcon={<AppsIcon />}
          onClick={() => handleButtonClick('apps')}
          sx={{ 
            backgroundColor: selectedDataType === 'apps' ? theme.palette.primary.main : '',
            color: selectedDataType === 'apps' ? theme.palette.text.primary : theme.palette.secondary.main,
            ':hover': {
              backgroundColor: selectedDataType === 'apps' ? theme.palette.primary.main : '',
              color: theme.palette.secondary.main
            }
          }}
        >
          Apps
        </Button>
        {/* <Button 
          startIcon={<AttachMoneyIcon />}
          onClick={() => handleButtonClick('billing')}
          sx={{ 
            backgroundColor: selectedDataType === 'billing' ? theme.palette.primary.main : '',
            color: selectedDataType === 'billing' ? theme.palette.text.primary : theme.palette.secondary.main,
            ':hover': {
              backgroundColor: selectedDataType === 'billing' ? theme.palette.primary.main : '',
              color: theme.palette.secondary.main
            }
          }}
        >
          Billing
        </Button> */}
        <Button 
          startIcon={<AppsIcon />}
          onClick={() => handleButtonClick('servers')}
          sx={{ 
            backgroundColor: selectedDataType === 'servers' ? theme.palette.primary.main : '',
            color: selectedDataType === 'servers' ? theme.palette.text.primary : theme.palette.secondary.main,
            ':hover': {
              backgroundColor: selectedDataType === 'servers' ? theme.palette.primary.main : '',
              color: theme.palette.secondary.main
            }
          }}
        >
          Servers
        </Button>
          </ButtonGroup>
          <Button
            size="large"
            startIcon={<BuildIcon />}
            variant='contained'
            color= "secondary"
            sx ={{
              display: "flex",
              marginTop: 1,
            }}
          >
            Audit Tool
          </Button>
          <Button
            size="large"
            startIcon={<BuildIcon />}
            variant='contained'
            color= "secondary"
            sx ={{
              display: "flex",
              marginTop: 1,
            }}
            onClick ={handleShowCloner}
          >
            App Cloner
          </Button>
          </Box>
          <Divider />
          <Box sx={{
              paddingLeft: 2,
              paddingRight: 1,
              display: "flex",
              flexDirection: 'column',
              height: '80vh',
              overflowY: 'scroll',
              scrollbarWidth: 'thin', // Add scrollbar width to ensure scrollbar is visible
              '&::-webkit-scrollbar': {
                width: '6px', // Width of the scrollbar
                background: '#F5F5F5', // Background color of the scrollbar
              },
              '&::-webkit-scrollbar-thumb': {
                background: theme.palette.primary.main, // Color of the scrollbar thumb
                borderRadius: '3px', // Border radius of the scrollbar thumb
              },
            }}>
            {error && <Typography variant = 'h4' sx={{color: theme.palette.secondary.main}}>Error pulling data... you should probably let Dan know...</Typography>}
            {isLoading && <Typography variant = 'h4' >Loading...</Typography>}
            <SideBarData 
              dataType= {selectedDataType}
              sx={{zIndex: -1}}
            />
          </Box>
        </Drawer>
      </Box>
      <Grid container sx={{ padding: '80px 20px' }} spacing={5}>
      {renderComponent()}
      </Grid>
    </Box>
  );
}

export default App;
