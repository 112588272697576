import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchServersData = createAsyncThunk(
    'servers/fetchData',
    async (_, { getState }) => {
      const { token } = getState().auth; // get token from store
      const response = await axios.get('https://sharpnetdevs.com/api/data/servers', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return JSON.parse(response.data);
    }
  );

const serversReducer = createSlice({
    name: 'servers',
    initialState: {
        data: [],
        filteredServersData: [],
        selectedServersCard: null,
        isLoading: false,
        error: null
    },
    reducers: {
        setSelectedServersCard(state, action) {
            state.selectedServersCard = action.payload
        },
        filterServers(state, action) {
            const searchTerm = action.payload.toLowerCase();
            state.filteredServersData = state.data.filter(
              app => app.name.toLowerCase().includes(searchTerm)
            );
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchServersData.pending, state => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchServersData.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload
                state.filteredServersData = state.data
            })
            .addCase(fetchServersData.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                console.log("Servers Fetch Error ", state.error)
            })
    }
})

export const { setSelectedServersCard, filterServers } = serversReducer.actions;
export default serversReducer.reducer
