import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchApplicationsData = createAsyncThunk(
    'applications/fetchData',
    async (_, { getState }) => {
      const { token } = getState().auth;
      const response = await axios.get('https://sharpnetdevs.com/api/data/applications', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return JSON.parse(response.data);
    }
  );  

const applicationsReducer = createSlice({
    name: 'applications',
    initialState: {
        data: [],
        filteredApplicationsData: [],
        selectedApplicationsCard: null,
        isLoading: false,
        error: null
    },
    reducers: {
        filterApplications(state, action) {
            const searchTerm = action.payload.toLowerCase();
            state.filteredApplicationsData = state.data.filter(
              app => app.name.toLowerCase().includes(searchTerm)
            );
        },
        updateApplicationNotes(state, action) {
            const { id, newNotes } = action.payload;

            const app = state.data.find(app => app.id === id);
            if (app) {
                app.notes = newNotes;
            }

            const filteredApp = state.filteredApplicationsData.find(app => app.id === id);
            if (filteredApp) {
                filteredApp.notes = newNotes;
            }

            
        },
        setSelectedApplicationsCard(state, action) {
            state.selectedApplicationsCard = action.payload
        },
        updateApplicationDevTemp(state, action) {
            const { id, newDevTempValue } = action.payload;

            state.selectedApplicationsCard.devTemp = newDevTempValue

            const dataApp = state.data.find(app => app.id === id)
            if (dataApp) {
                dataApp.devTemp = newDevTempValue
            }

            const filteredApp = state.filteredApplicationsData.find(app => app.id === id);
            if (filteredApp) {
                filteredApp.devTemp = newDevTempValue;
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchApplicationsData.pending, state => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchApplicationsData.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload
                state.filteredApplicationsData = state.data
                // console.log("fetching complete")
            })
            .addCase(fetchApplicationsData.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                console.log("Fetch Error ", state.error)
            })
    }
})

export const { filterApplications, updateApplicationNotes, setSelectedApplicationsCard, updateApplicationDevTemp } = applicationsReducer.actions;
export default applicationsReducer.reducer
