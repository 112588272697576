import { combineReducers } from '@reduxjs/toolkit';
import applicationsReducer from './applications/reducer'
import serversReducer from './servers/reducer'
import authReducer from './auth/reducers';

const rootReducer = combineReducers({
    applications: applicationsReducer,
    servers: serversReducer,
    auth: authReducer
})

export default rootReducer;