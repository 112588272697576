import React, { useState } from "react";
import { FormControl } from '@mui/material';

import { Container, TextField, Button, Paper, Box, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import '../style/Login.css'
import axios from 'axios';

import { useSelector, useDispatch } from 'react-redux'
import { setIsAuthenticated, setToken } from "../store/auth/reducers";


const Login = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loginMessage, setLoginMessage] = useState('')

  const theme = useTheme()

  const dispatch = useDispatch()

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated); // determine if authorized, from context or however you're doing it
  console.log(isAuthenticated)
  const handleUsernameChange = (text) => {
    setUsername(text);
  };

  const handlePasswordChange = (text) => {
    setPassword(text);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  
    axios.post('https://sharpnetdevs.com/api/login', {
      username: username,
      password: password
    })
    .then((response) => {
      console.log('Login Success');
      dispatch(setIsAuthenticated(true))
      dispatch(setToken(response.data.token))
      localStorage.setItem('token', response.data.token)
    })
    .catch((error) => {
      console.log(error);
      setLoginMessage('Login failed. Please try again.')
    })
  }

  return (
    <div
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        borderRadius: 16
      }}
    >
      <Container maxWidth="xs">
        <Paper
          sx={{
            padding: 2,
            width: 400,
            marginTop: '25vh'
          }}
          elevation={3}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2
            }}
          >
            <Typography variant="h4" component="h1" sx={{color: theme.palette.primary.main}}>
              Login
            </Typography>
            <form onSubmit={handleSubmit} className="loginContainer">
            <TextField className="loginTextInput" label="Username" variant="outlined" value={username} onChange={(event) => handleUsernameChange(event.target.value)} inputProps={{style: {color: theme.palette.primary.main}}}/>
            <TextField className="loginTextInput" label="Password" variant="outlined" type="password" value={password} onChange={(event) => handlePasswordChange(event.target.value)} inputProps={{style: {color: theme.palette.primary.main}}}/>
            <Button type="submit" variant="contained" color="primary">
              Login
            </Button>
            </form>
            <p className="errorMessage">
              {loginMessage}
            </p>
          </Box>
        </Paper>
      </Container>
    </div>
  );
};

export default Login;
