import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import store from './store/store';

import AuthWrapper from './AuthWrapper';

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = createTheme({
  palette: {
    primary: {
      main: '#020241',
    },
    secondary: {
      main: '#AA0101',
    },
    text: {
      primary: '#FFFFFF'
    }
  }
});

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <AuthWrapper />
    </ThemeProvider>
  </Provider>
);
