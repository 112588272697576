import { Grid, Typography, Card, FormControlLabel, Checkbox, TextField, Box, Link, Alert } from "@mui/material"
import LaunchIcon from '@mui/icons-material/Launch';
import { useTheme } from '@mui/material/styles';

import { useDispatch, useSelector } from 'react-redux'
import { updateApplicationNotes, updateApplicationDevTemp } from '../store/applications/reducer'

import axios from "axios";

import { useState, useCallback, useEffect } from "react";
import { debounce } from "lodash";


function DetailedServers() {
    const selectedServersCard = useSelector(state => state.servers.selectedServersCard)
    const token = useSelector(state => state.auth.token); // Get token from Redux state

    const [localNotes, setLocalNotes] = useState();

    useEffect(() => {
        if(selectedServersCard) {
            setLocalNotes(selectedServersCard.notes)
        }
    },[selectedServersCard])
    
    const dispatch = useDispatch()
    
    const [showAlert, setShowAlert] = useState(false);

    const theme = useTheme()

    const copyToClipboard = (copy) => {
        navigator.clipboard.writeText(copy)
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 2500);
      };
    
      const handleNotesChange = useCallback(
        debounce(async (id, newNotes) => {
          try {
            const response = await axios.put(`https://sharpnetdevs.com/api/data/applications/notes/${id}`, {
              notes: newNotes
            }, {
              headers: {
                Authorization: `Bearer ${token}`
              }
            });
            dispatch(updateApplicationNotes({id, newNotes}))
            console.log(response.data);
            // Handle the response as needed
          } catch (error) {
            console.log(error);
            // Handle the error as needed
          }
        }, 500), [token, dispatch]
      );
    const handleChange = (id, newNotes) => {
        setLocalNotes(newNotes);
        handleNotesChange(id, newNotes);
    };

    const handleDevTempChange = (event) => {
      const newDevTempValue = event.target.checked;
      const id = selectedServersCard.id;
    
      // Send PUT request to update devTemp field in backend
      axios.put(`https://sharpnetdevs.com/api/data/applications/devTemp/${id}/${String(newDevTempValue)}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(response => {
          dispatch(updateApplicationDevTemp({id, newDevTempValue}));
          // Handle success response as needed
          // console.log(response.data);
        })
        .catch(error => {
          // Handle error as needed
          console.error(error);
        });
    };
    
      
    

    const renderServers = () => {
        if(selectedServersCard) {
            return(
                <>
                    {showAlert && (
                        <Alert
                        sx={{
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            width: '10%',
                            zIndex: (theme) => theme.zIndex.snackbar + 1,
                            margin: 1,
                        }}
                        severity="success"
                        onClose={() => setShowAlert(false)}
                        >
                        Copied! 
                        </Alert>
                    )}
                    <Grid item xs={12}>
                        {selectedServersCard.name ? 
            
                        <Typography variant='h3' color={theme.palette.primary.main} fontWeight="bold">
                            {selectedServersCard.name}
                        </Typography> 
                        :
                        <Typography variant='h3' color={theme.palette.primary.main} fontWeight="bold">
                            No "name" found
                        </Typography>
                        }
                    </Grid >
                    <Grid item xs={2}>
                        <Card onClick = {() => copyToClipboard(selectedServersCard.id)} sx = {{padding: 2, background: theme.palette.primary.main}}>
                            <Typography variant='h6'>
                            ID
                            </Typography>
                            <Typography variant='p'>
                            {selectedServersCard.id}
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={2}>
                        <Card sx = {{padding: 2, background: theme.palette.primary.main}}>
                            <Typography variant='h6'>
                            STATUS
                            </Typography>
                            <Typography variant='p'>
                            {selectedServersCard.status}
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={2}>
                        <Card sx = {{padding: 2, background: theme.palette.primary.main}}>
                            <Typography variant='h6'>
                            IP
                            </Typography>
                            <Typography variant='p'>
                            {selectedServersCard.ip}
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={2}>
                        <Card sx = {{padding: 2, background: theme.palette.primary.main}}>
                            <Typography variant='h6'>
                            SERVER SIZE
                            </Typography>
                            <Typography variant='p'>
                            {selectedServersCard.server_size}
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card sx = {{padding: 2, background: theme.palette.primary.main}}>
                            <Typography variant='h6'>
                            SMTP EMAIL
                            </Typography>
                            <Typography variant='p'>
                            {selectedServersCard.smtp_email}
                            </Typography>
                        </Card>
                    </Grid>
                      <Grid item xs={12}>
                      <TextField
                        id="outlined-multiline-static"
                        label="Notes"
                        multiline
                        rows={4}
                        fullWidth={true}
                        value={localNotes}
                        onChange = {(e) => handleChange(selectedServersCard.id, e.target.value)}
                        sx={{
                            '&:hover:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
                              borderColor: theme.palette.secondary.main,
                            }
                        }}
                        InputProps={{
                            style: {
                              color: theme.palette.primary.main,
                            },
                          }}
                        InputLabelProps={{ shrink: true }}
                       />
                      </Grid>
                </>
            )
        }
        else {
            return(
                <Typography variant = 'h4' sx={{color: theme.palette.secondary.main, padding: '45px 40px'}}>Select a project to display more information</Typography>
            )
        }
    }
    
    return(
      renderServers()
    )
}


export default DetailedServers;