import React, { useState } from "react";
import { useSelector } from 'react-redux'
import axios from "axios";

import { useTheme } from '@mui/material/styles';
import { Card, Typography, Grid, FormControl, Select, MenuItem, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';


function AppCloner(props) {
const theme = useTheme()
// Define state variables using the useState hook
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [selectedApp, setSelectedApp] = useState('');
  const [selectedServer, setSelectedServer] = useState('');

  const appList = useSelector(state => state.applications.data)
  const serverList = useSelector(state => state.servers.data)
  const token = useSelector(state => state.auth.token); // Get token from Redux state
  const [appListSearch, setAppListSearch ] = useState()

  function handleClose(){
    // console.log("close")
    props.setShowCloner(false);
  }


  // Function to clone an application to another server
  async function cloneSite() {

    // Log the selected IDs
    console.log("Selected App :", selectedApp);
    console.log("Selected App Server ID:", selectedServer);

    setAppListSearch(appList.filter((app) => app.id === selectedApp))

    console.log(appListSearch)

    try {
        const response = await axios.post('https://sharpnetdevs.com/api/applications/clone_app', 
        {
            server_id: appListSearch[0].server_id,
            app_id: selectedApp,
            destination_server_id: selectedServer

        }, {
          headers: {
            Authorization: `Bearer ${token}` // Pass token in the Authorization header
          }
        });
        console.log(response.data);
        setSuccessMessage(true)
      } 
      catch (error) {
        console.log(error);
        setErrorMessage(true)
      }
    }

  return (
<Card
  id="appCloner"
  sx={{
    position: "fixed",
    width: "60vw",
    zIndex: 1201,
    background: theme.palette.primary.main,
    top: "25vh",
    left: "25vw",
    padding: "10px"
  }}
>
  <CloseIcon
    sx={{
      position: 'absolute',
      top: '10px',
      left: '10px',
      color: 'white',
      cursor: 'pointer'
    }}
    onClick={handleClose}
  />
  <Grid container spacing={2}>
    <Grid item xs={6}>
      <Typography variant="h2" sx={{ color: "white", textAlign: "center" }}>
        App
      </Typography>
      <FormControl sx={{ width: "100%", border: "1px solid white" }}>
        <Select
          value={selectedApp}
          onChange={(event) => setSelectedApp(event.target.value)}
          displayEmpty
        >
          <MenuItem value="" disabled sx={{ color: theme.palette.primary.main }}>
            Select an option
          </MenuItem>
          {appList.map(app => (
            <MenuItem key={app.id} value={app.id} sx={{ color: theme.palette.primary.main }}>
              {app.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
    <Grid item xs={6}>
      <Typography variant="h2" sx={{ color: "white", textAlign: "center" }}>
        Server
      </Typography>
      <FormControl sx={{ width: "100%", border: "1px solid white" }}>
        <Select
          value={selectedServer}
          onChange={(event) => setSelectedServer(event.target.value)}
          displayEmpty
        >
          <MenuItem value="" disabled sx={{ color: theme.palette.primary.main }}>
            Select an option
          </MenuItem>
          {serverList
            .slice()
            .sort((a, b) => a.app_count - b.app_count)
            .map(server => (
              <MenuItem key={server.id} value={server.id} sx={{ color: theme.palette.primary.main }}>
                {`${server.name} | Apps: ${server.app_count}`}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Grid>
  </Grid>
    <Button 
      startIcon={<ContentCopyIcon />}
      onClick = {cloneSite}
      sx={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, width: "100%", marginTop: "10px" }}
      variant='contained'
      color= "secondary"
    >
      <Typography variant="h5" >
      Clone!
      </Typography>
    </Button>
    <Grid container spacing={2}>
      <Grid item xs={12}>
      <Typography variant="h5" sx={{ color: "white", textAlign: "center" }}>
      {successMessage && "Clone Success"}
      {errorMessage && "Clone Failed"}
      </Typography>
      </Grid>
    </Grid>
</Card>
  );
}

export default AppCloner;
